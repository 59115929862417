import { isStudentPad, isTeacherPad } from '@/util/env';
import { getStore } from '@/util/store';
export const getRole = () => {
  const { memberType, identityType } = getStore({ name: 'userInfo' }) || {};
  if (identityType === 2) {
    return 'customer';
  } else if (isTeacherPad) {
    return 'teacher';
  } else if (isStudentPad) {
    return 'student';
  } else {
    if (memberType && memberType.length === 1 && memberType[0] === 'student') {
      return 'student';
    } else {
      return 'teacher';
    }
  }
};
